import axios from "axios";
import { isLocalServer } from "../Components/isLocalServer";
import { getNodeServerUrl } from "../utils/getNodeServerUrl";

export const getGlobalAccessTokenApi = async () => {
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // const nodeBaseUrl = isLocalServer()
  //   ? "http://localhost:5000"
  //   : "https://hester-npharma-uat-2ffe9a99645d.herokuapp.com";
  
  const nodeBaseUrl = getNodeServerUrl();

  const options = {
    url: `${nodeBaseUrl}/login/get-access-token`,
    method: "POST",
    headers: headers,
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    console.log(error);
  }
};
