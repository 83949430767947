import React, { useEffect, useState, forwardRef } from "react";
import { Card } from "react-bootstrap";
import { formatDateStringDDMMYYYY } from "../../Common/functions";
import { jointWorkingApi } from "../../services/reports/attendanceClaimDcrApi";
import { useSelector } from "react-redux";
import * as XLSX from 'xlsx';
import CommonButton from "../../Common/CommonButton";
import { reportDownloadApi } from "../../services/reports/reportDownloadApi";
import { jointWorkingReportDownloadApi } from "../../services/reports/jointWorkingReportApi";
import { toast, ToastContainer } from "react-toastify";

const JointWorkingReport = forwardRef((props, ref) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filterEmpId, setFilterEmpId] = useState('');
    const [loading, setLoading] = useState(false);
    const [jointWork, setJointWork] = useState([]);
    const [error, setError] = useState('');
    const [dataFetched, setDataFetched] = useState(false);



    const EmployeeId = useSelector((state) => state?.userDetails?.details?.Id);
    const accessToken = useSelector((state) => state?.accessToken?.accessToken);
    const designation = useSelector((state) => state?.userDetails?.details?.Designation__c);
    const empId = useSelector((state) => state?.userDetails?.details?.Id);
    const division = useSelector((state) => state?.userDetails?.details?.Division__c);


    const getDayMonth = (dateString) => {
        const date = new Date(dateString);
        const options = { month: 'long' };
        const day = date.getDate();
        const month = new Intl.DateTimeFormat('en-US', options).format(date);
        const weekday = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(date);

        return { day, month, weekday };
    };

    const fetchData = async () => {
        setLoading(true); // Start loading state

        try {
            // Fetch data from API
            const response = await jointWorkingReportDownloadApi(
                accessToken,
                filterEmpId,
                startDate,
                endDate,
                designation,
                empId,
                '4000',
                division
            );

            const dataArray = JSON.parse(response.data); // Parse the response data

            // Check if the response contains an error message
            // if (dataArray?.error) {
            //     toast.error(dataArray?.error); // Show the error message in an alert
            //     setJointWork([]); // Reset the data to an empty array
            //     setDataFetched(false); // Indicate no data was fetched
            //     return; // Exit the function
            // }

            // If no records are returned or the response is empty, reset the state
            if (!dataArray || !dataArray.records || dataArray.records.length === 0) {
                setJointWork([]); // Reset the data to an empty array
                setDataFetched(false); // No data was fetched
                return;
            }

            // Access the array of records
            const { records } = dataArray;

            // Set default dates if not provided
            const start = startDate ? new Date(startDate) : new Date("1900-01-01");
            const end = endDate ? new Date(endDate) : new Date("2100-01-01");
            start.setHours(0, 0, 0, 0);
            end.setHours(23, 59, 59, 999);

            // Filter the data based on the date range and employee ID
            const filteredData = records.filter((item) => {
                const itemDate = new Date(item?.Date__c);
                return itemDate >= start && itemDate <= end;
            });
            

            console.log("Filtered Data:", filteredData);

            // Sort the filtered data by 'Date__c' in descending order
            const sortedData = filteredData.sort(
                (a, b) => new Date(b?.Date__c) - new Date(a?.Date__c)
            );

            console.log("Sorted Data:", sortedData);

            // Update state with the sorted data
            setJointWork(sortedData);
            setDataFetched(sortedData.length > 0); // Set dataFetched based on the presence of data

        } catch (error) {
            if (error?.response?.status === 400) {
                toast.error("Provided employee code is not a valid subordinate.");
                setJointWork([]); // Reset data to an empty array
            } else {
                console.error("Error fetching tour plans:", error);
                setJointWork([]); // Reset data to an empty array in case of other errors
            }
            setDataFetched(false); // Indicate no data was fetched in case of error
        } finally {
            setLoading(false); // End loading state
        }
    };


    // useEffect(() => {
    //     const fetchData = async () => {
    // setLoading(true);
    //         const limitSize = 2000; // Number of records to fetch per API call
    //         let lastId = null;      // ID of the last fetched record
    //         let allData = [];

    //         try {
    //             while (true) {
    //                 // Fetch data with pagination parameters
    //                 const jointWorkingResponse = await jointWorkingApi(
    //                     accessToken,
    //                     designation,
    //                     EmployeeId,
    //                     limitSize,
    //                     lastId // Pass lastId to fetch the next batch of records
    //                 );

    //                 const dataArray = jointWorkingResponse?.data?.records || [];
    //                 console.log("Fetched Data:", dataArray);

    //                 // Exit loop if no more data is returned
    //                 if (dataArray.length === 0) {
    //                     break;
    //                 }

    //                 // Accumulate data
    // allData = [...allData, ...dataArray];

    //                 // Update lastId to the ID of the last fetched record
    //                 lastId = dataArray[dataArray.length - 1]?.Id; // Adjust this according to your data structure
    //             }

    //             // Filter and sort the accumulated data
    //             const start = startDate ? new Date(startDate) : new Date('1900-01-01');
    // const end = endDate ? new Date(endDate) : new Date('2100-01-01');
    // start.setHours(0, 0, 0, 0);
    // end.setHours(23, 59, 59, 999);

    //             const filteredData = allData.filter(item => {
    //                 const itemDate = new Date(item?.Date__c);
    //                 return filterEmpId ?
    //                     (itemDate >= start && itemDate <= end && filterEmpId === item?.Contact__r?.EmpId__c) :
    //                     (itemDate >= start && itemDate <= end);
    //             });

    //             const sortedData = filteredData.sort((a, b) => new Date(b?.Date__c) - new Date(a?.Date__c));

    //             setJointWork(sortedData);
    //         } catch (error) {
    //             console.error('Error fetching joint working data:', error);
    //         } finally {
    // setLoading(false);
    //         }
    //     };

    //     fetchData();
    // }, [EmployeeId, accessToken, designation, filterEmpId, startDate, endDate]);

   console.log("filter emp id",filterEmpId)

    const handleKeyPress = (e) => {
        const charCode = e.which ? e.which : e.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
            setError('Please enter only numbers.');
        } else {
            setError('');
        }
    };

    const handleExport = () => {
        try {
            if (!jointWork || jointWork.length === 0) {
                alert('No data available to export');
                return;
            }

            // Convert data to worksheet
            const worksheet = XLSX.utils.json_to_sheet(jointWork.map(claim => ({
                'Employee Code': claim?.Tour_Plan_Approval__r?.EmpId__c || 'N/A',
                'Employee Name': claim?.Tour_Plan_Approval__r?.Name || 'N/A',
                'HQ': claim?.Contact__r?.HQ__c || 'N/A',
                'Month': getDayMonth(claim?.Date__c)?.month || 'N/A',
                'Date': formatDateStringDDMMYYYY(claim?.Date__c) || 'N/A',
                'Day': getDayMonth(claim?.Date__c)?.weekday || 'N/A',
                'Joint Working HQ': claim?.Contact__r?.HQ__c || 'N/A',
                'Joint Working Emp Name': claim?.Contact__r?.Name || 'N/A',
                'Joint Working Emp Code': claim?.Contact__r?.EmpId__c || 'N/A',
            })));

            // const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Joint Working");

            XLSX.writeFile(workbook, "Joint_Working_Report.xlsx");

        } catch (error) {
            console.error('Error generating Excel file:', error);
            alert('Failed to generate Excel file. Please check the console for details.');
        }
    };

    const handleSubmit = () => {
        fetchData();
    };
    return (
        <>
            <div className="markAttendance_section container-fluid">
                <div className="row mb-5">
                    <Card className="monthlyClaim_mobile">
                        <Card.Body>
                            <h4 className="mb-4 text-center">Joint Working Report</h4>
                            <div className="filter-section mb-4">
                                <label style={{ marginRight: '1rem' }}>
                                    Start Date
                                    <input
                                        className="CommonDropdown form-select"
                                        type="date"
                                        value={startDate}
                                        style={{
                                            width: "100%",
                                            color: "rgb(46, 49, 146)",
                                            fontSize: "14px",
                                            backgroundImage: "none",
                                            paddingRight: "14px",
                                        }}
                                        onChange={e => setStartDate(e.target.value)}
                                    />
                                </label>
                                <label style={{ marginRight: '1rem' }}>
                                    End Date
                                    <input
                                        className="CommonDropdown form-select"
                                        type="date"
                                        style={{
                                            width: "100%",
                                            color: "rgb(46, 49, 146)",
                                            fontSize: "14px",
                                            backgroundImage: "none",
                                            paddingRight: "14px",
                                        }}
                                        value={endDate}
                                        onChange={e => setEndDate(e.target.value)}
                                    />
                                </label>
                                <label style={{ marginRight: '1rem' }}>
                                    EmpId
                                    <input
                                        className="CommonDropdown form-select"
                                        type="text"
                                        maxLength={4}
                                        style={{
                                            width: "8rem",
                                            color: "rgb(46, 49, 146)",
                                            fontSize: "14px",
                                            backgroundImage: "none",
                                            paddingRight: "14px",
                                        }}
                                        value={filterEmpId}
                                        onChange={e => setFilterEmpId(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                    />
                                </label>
                                <span onClick={handleSubmit}>
                                    <CommonButton title={"Submit"} className="btn btn-primary" />
                                </span>
                                {dataFetched && (
                                    <span style={{ marginLeft: "10px" }} onClick={handleExport}>
                                        <CommonButton
                                            title={'Download Excel'}
                                            className="btn btn-primary"
                                            onClick={handleExport}
                                        />
                                    </span>
                                )}
                            </div>
                            {error && <span className="dcr-form-errors">{error}</span>}
                            {loading ? (
                                <div>Loading...</div>
                            ) : (
                                dataFetched && (
                                    <div
                                        id="table-to-xls"
                                        className="table-wrapper-scroll-y custom-scrollbar"
                                        style={{ padding: '20px', overflow: 'auto' }}
                                    >
                                        <table
                                            className="w-100"
                                            style={{ textAlign: "center" }}
                                            ref={ref}
                                        >
                                            <thead>
                                                <tr className="ladder_heading">
                                                    <th className="custom-header">Employee Code</th>
                                                    <th className="custom-header">Employee Name</th>
                                                    <th className="custom-header">HQ</th>
                                                    <th className="custom-header">Month</th>
                                                    <th className="custom-header">Date</th>
                                                    <th className="custom-header">Day</th>
                                                    <th className="custom-header">Joint Working HQ</th>
                                                    <th className="custom-header">Joint Working Emp Name</th>
                                                    <th className="custom-header">Joint Working Emp Code</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {jointWork?.map((claim, index) => {
                                                    const { month, weekday } = getDayMonth(claim?.Date__c);
                                                    return (
                                                        claim?.Status__c === "Tour Plan Cloned" && (
                                                            <tr key={index} className="ladder_heading_data">
                                                                <td className='pl-3'>{claim?.Tour_Plan_Approval__r?.EmpId__c}</td>
                                                                <td className='pl-3'>{claim?.Tour_Plan_Approval__r?.Name}</td>
                                                                <td className='pl-3'>{claim?.Contact__r?.HQ__c}</td>
                                                                <td className='pl-3'>{month}</td>
                                                                <td className='pl-3'>{formatDateStringDDMMYYYY(claim?.Date__c)}</td>
                                                                <td className='pl-3'>{weekday}</td>
                                                                <td className='pl-3'>{claim?.Contact__r?.HQ__c}</td>
                                                                <td className='pl-3'>{claim?.Contact__r?.Name}</td>
                                                                <td className='pl-3'>{claim?.Contact__r?.EmpId__c}</td>
                                                            </tr>
                                                        )
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            )}
                        </Card.Body>
                    </Card>
                    <ToastContainer />
                </div>
            </div>
        </>
    );
});

export default JointWorkingReport;