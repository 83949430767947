import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import BackButton from "../../Common/BackButton";
import { useSelector } from "react-redux";
import { getApproveTPManagerApi } from "../../services/NTP/getApproveTPManagerApi";
import Loader from "../../Common/Loader";
import { Card } from "react-bootstrap";
import CommonButton from "../../Common/CommonButton";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { format } from "date-fns";
import check from "./../../assets/images/check-mark-3280.svg";
import question from "./../../assets/images/red-question-11802.svg";
import draft from "./../../assets/images/draft.png";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import remove from "./../../assets/images/remove.png";

const ManagerApprovePlans = () => {
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const Id = useSelector((state) => state?.userDetails?.details?.Id);

  const [loading, setLoading] = useState(false);
  const [approvePlans, setApprovePlans] = useState([]);
  const [lastRecordId, setLastRecordId] = useState(null); // To keep track of the last record ID
  const [hasMore, setHasMore] = useState(true);

  console.log("last record id", lastRecordId)

  const fetchAllApprovePlans = async () => {
    setLoading(true);
    try {
      const response = await getApproveTPManagerApi(Id, accessToken, lastRecordId);
      if (response?.status === 200) {
        const newRecords = response.data.records;
        const newrecordid = response.data.lastRecordId;
        console.log(newrecordid)
        console.log("new records", newRecords)
        setApprovePlans(prev => [...prev, ...newRecords]); // Append new records to existing
        // Update lastRecordId if there are new records
        if (newRecords.length > 0) {
          setLastRecordId(response.data.lastRecordId); // Set to the ID from the response
        } else {
          setHasMore(false); // No more records to load
        }
      }
    } catch (error) {
      console.error("Error fetching plans:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllApprovePlans(); // Fetch on component mount
  }, [Id, accessToken]);

  const loadMore = () => {
    if (hasMore && !loading) {
      fetchAllApprovePlans(); // Call to fetch more records
    }
  };

  const combinedCount = approvePlans.reduce((acc, obj) => {
    const key = `${obj?.TourPlan_SR_No__c}_${obj?.Date__c}_${obj?.Type_of_Tour_Plan__c}_${obj?.Place__c}`; // Create a combined key using both Date__c and status
    acc[key] = acc[key]
      ? { count: acc[key].count + 1, ...obj }
      : { count: 1, ...obj };
    return acc;
  }, {});

  const mappedData = Object.entries(combinedCount).map(
    ([key, { count, ...rest }]) => ({
      ...rest,
      count,
    })
  );

  mappedData.sort((a, b) => new Date(a.Date__c) - new Date(b.Date__c));

  const pendingMessage = `Pending - ${mappedData.filter(data => data.Status__c === "Pending For Approval").length}`;
  const approvedMessage = `Approved/Rejected - ${mappedData.filter(data => data.Status__c === "Approved" || data.Status__c === "Rejected" || data?.Status__c === "Tour Plan Cloned").length}`;
  const deletedMessage = `Deleted - ${mappedData.filter(data => data.Status__c === "Delete Request Sent To Manager").length}`;

  // const pendingCount = `${mappedData.filter(data => data.Status__c === "Pending For Approval").length}`;
  // const approvedCount = `${mappedData.filter(data => data.Status__c === "Approved" || data.Status__c === "Rejected" || data?.Status__c === "Tour Plan Cloned").length}`;
  // const deletedCount = `${mappedData.filter(data => data.Status__c === "Delete Request Sent To Manager").length}`;

  return (
    <div>
      <Helmet>
        <title>Approve Plans</title>
      </Helmet>

      <div className="container-fluid">
        <div className="MTP_heading mt-4 mb-4">
          <div className="row">
            <BackButton title={"Approve Plans"} />
            <div className="col-6 text-right"></div>
          </div>
        </div>
        <div className="markAttendance Approve_plansTab_heading">
          <Tabs
            defaultActiveKey="Pending"
            id="fill-tab-example"
            className="my-3 markAttendance_tab Approve_plansTab"
            fill
            style={{ display: "flex" }}
          >
            <Tab
              eventKey="Pending"
              title={pendingMessage}
              className="markAttendance_tabsSection mt-4 mb-4"
            >
              <div className="markAttendance_section container-fluid">
                <div className="row mb-5">
                  {/* {mappedData.filter(data => data.Status__c === "Pending For Approval").length === 0 && <p className="mt-5" style={{ fontWeight: 'bold' }}>No records to display</p>} */}
                  {mappedData.filter(data => data.Status__c === "Pending For Approval").map((data) => {
                    return (
                      <div className="MTP_mainContent mt-4">
                        <Card>
                          <Card.Body style={{ padding: "10px" }}>
                            <div className="row">
                              <div
                                className="col-lg-2 col-md-2 col-6  MTP_tableHeading"
                                style={{ fontWeight: "700" }}
                              >
                                Tour Date{" "}
                                <p className="MTP_data">
                                  {format(new Date(data.Date__c), "dd MMM yyyy")}
                                </p>
                              </div>
                              <div
                                className="col-lg-2 col-md-2 col-6 MTP_tableHeading"
                                style={{ fontWeight: "700" }}
                              >
                                MR Name
                                <br />
                                <span className="MTP_data">
                                  {data?.Contact__r?.Name}
                                </span>
                              </div>
                              <div
                                className="col-lg-2 col-md-2 col-6 MTP_tableHeading"
                                style={{ fontWeight: "700" }}
                              >
                                Tour Type{" "}
                                <p className="MTP_data">
                                  {data?.Type_of_Tour_Plan__c}
                                </p>
                              </div>
                              <div
                                className="col-lg-2 col-md-2 col-6 MTP_tableHeading"
                                style={{ fontWeight: "700" }}
                              >
                                {data?.Type_of_Tour_Plan__c === "Field Work" ? (
                                  <>
                                    No. of Customers
                                    <br />
                                    <span className="MTP_data">
                                      {data?.count}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    Place
                                    <br />
                                    <span className="MTP_data">
                                      {data?.Place__c}
                                    </span>
                                  </>
                                )}
                              </div>
                              <div
                                className="col-lg-2 col-md-2 col-6 MTP_tableHeading"
                                style={{ fontWeight: "700" }}
                              >
                                Status
                                <div className="MTP_data">
                                  {data?.Status__c === "Pending For Approval" ? (
                                    <img
                                      className="MTP_approved"
                                      src={question}
                                      alt=""
                                    />
                                  ) : data?.Status__c === "Saved" ? (
                                    <img
                                      className="MTP_draft"
                                      src={draft}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      className="MTP_approved"
                                      src={check}
                                      alt=""
                                    />
                                  )}
                                  <span
                                    style={{ color: "black", fontWeight: "500" }}
                                  >
                                    {data?.Status__c}
                                  </span>
                                  {/* <p className="MTP_tableData">{data?.Date__c}</p> */}
                                </div>
                              </div>
                              <div
                                className="col-lg-2 col-md-2 col-6 MTP_tableHeading"
                                style={{ fontWeight: "700" }}
                              >
                                {data?.Type_of_Tour_Plan__c === "Field Work" ? (
                                  <Link
                                    to={{
                                      pathname: `/view-tours/${data?.TourPlan_SR_No__c}:${data?.Date__c}:${data?.Contact__c}`,
                                    }}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <p className="mt-3">
                                      <CommonButton
                                        title={"View Details"}
                                        width={"7rem"}
                                      />
                                    </p>
                                  </Link>
                                )
                                  : (
                                    <Link
                                      to={{
                                        pathname: `/view-tours/${data?.TourPlan_SR_No__c}:${data?.Date__c}:${data?.Contact__c}:${encodeURIComponent(data?.Place__c)}`,
                                      }}
                                      style={{ textDecoration: "none" }}
                                    >
                                      <p className="mt-3">
                                        <CommonButton
                                          title={"View Details"}
                                          width={"7rem"}
                                        />
                                      </p>
                                    </Link>
                                  )}
                              </div>

                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    );
                  })}
                </div>
              </div>
              {hasMore && (
                <span onClick={loadMore}>
                  <p className="mt-5" style={{ fontWeight: 'bold' }}>Click 'Load More' to see more records.</p>
                  <CommonButton title={"Load More"} />
                </span>
              )}
            </Tab>
            <Tab
              eventKey="Approved"
              title={approvedMessage}
              className="markAttendance_tabsSection mt-4 mb-4"
            >
              <div className="markAttendance_section container-fluid">
                <div className="row mb-5">
                  {/* {mappedData.filter(data => data.Status__c === "Approved" || data.Status__c === "Rejected" || data?.Status__c === "Tour Plan Cloned")?.length === 0 && <p className="mt-5" style={{ fontWeight: 'bold' }}>No records to display</p>} */}
                  {mappedData.filter(data => data.Status__c === "Approved" || data.Status__c === "Rejected" || data?.Status__c === "Tour Plan Cloned")?.sort((a, b) => new Date(b.Date__c) - new Date(a.Date__c))?.map((data) => {
                    return (
                      <div className="MTP_mainContent mt-4">
                        <Card>
                          <Card.Body style={{ padding: "10px" }}>
                            <div className="row">
                              <div
                                className="col-lg-2 col-md-2 col-6  MTP_tableHeading"
                                style={{ fontWeight: "700" }}
                              >
                                Tour Date{" "}
                                <p className="MTP_data">
                                  {format(new Date(data.Date__c), "dd MMM yyyy")}
                                </p>
                              </div>
                              <div
                                className="col-lg-2 col-md-2 col-6 MTP_tableHeading"
                                style={{ fontWeight: "700" }}
                              >
                                MR Name
                                <br />
                                <span className="MTP_data">
                                  {data?.Contact__r?.Name}
                                </span>
                              </div>
                              <div
                                className="col-lg-2 col-md-2 col-6 MTP_tableHeading"
                                style={{ fontWeight: "700" }}
                              >
                                Tour Type{" "}
                                <p className="MTP_data">
                                  {data?.Type_of_Tour_Plan__c}
                                </p>
                              </div>
                              <div
                                className="col-lg-2 col-md-2 col-6 MTP_tableHeading"
                                style={{ fontWeight: "700" }}
                              >
                                {data?.Type_of_Tour_Plan__c === "Field Work" ? (
                                  <>
                                    No. of Customers
                                    <br />
                                    <span className="MTP_data">
                                      {data?.count}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    Place
                                    <br />
                                    <span className="MTP_data">
                                      {data?.Place__c}
                                    </span>
                                  </>
                                )}
                              </div>
                              <div
                                className="col-lg-2 col-md-2 col-6 MTP_tableHeading"
                                style={{ fontWeight: "700" }}
                              >
                                Status
                                <div className="MTP_data">
                                  {data?.Status__c === "Pending For Approval" ? (
                                    <img
                                      className="MTP_approved"
                                      src={question}
                                      alt=""
                                    />
                                  ) : data?.Status__c === "Rejected" ? (
                                    <img
                                      className="MTP_draft mr-1"
                                      src={remove}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      className="MTP_approved"
                                      src={check}
                                      alt=""
                                    />
                                  )}
                                  <span
                                    style={{ color: "black", fontWeight: "500" }}
                                  >
                                    {data?.Status__c}
                                  </span>
                                  {/* <p className="MTP_tableData">{data?.Date__c}</p> */}
                                </div>
                              </div>
                              <div
                                className="col-lg-2 col-md-2 col-6 MTP_tableHeading"
                                style={{ fontWeight: "700" }}
                              >
                                {data?.Type_of_Tour_Plan__c === "Field Work" ? (
                                  <Link
                                    to={{
                                      pathname: `/view-tours/${data?.TourPlan_SR_No__c}:${data?.Date__c}:${data?.Contact__c}`,
                                    }}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <p className="mt-3">
                                      <CommonButton
                                        title={"View Details"}
                                        width={"7rem"}
                                      />
                                    </p>
                                  </Link>)
                                  : (
                                    <Link
                                      to={{
                                        pathname: `/view-tours/${data?.TourPlan_SR_No__c}:${data?.Date__c}:${data?.Contact__c}:${data?.Place__c}`,
                                      }}
                                      style={{ textDecoration: "none" }}
                                    >
                                      <p className="mt-3">
                                        <CommonButton
                                          title={"View Details"}
                                          width={"7rem"}
                                        />
                                      </p>
                                    </Link>
                                  )}
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    );
                  })}
                </div>
                {hasMore && (
                  <span onClick={loadMore}>
                    <p className="mt-5" style={{ fontWeight: 'bold' }}>Click 'Load More' to see more records.</p>
                    <CommonButton title={"Load More"} />
                  </span>
                )}
              </div>
            </Tab>
            <Tab
              eventKey="Deleted"
              title={deletedMessage}
              className="markAttendance_tabsSection mt-4 mb-4"
            >
              <div className="markAttendance_section container-fluid">
                <div className="row mb-5">
                  {/* {mappedData.filter(data => data.Status__c === "Delete Request Sent To Manager").length === 0 && <p className="mt-5" style={{ fontWeight: 'bold' }}>No records to display</p>} */}
                  {mappedData.filter(data => data.Status__c === "Delete Request Sent To Manager").map((data) => {
                    return (
                      <div className="MTP_mainContent mt-4">
                        <Card>
                          <Card.Body style={{ padding: "10px" }}>
                            <div className="row">
                              <div
                                className="col-lg-2 col-md-2 col-6  MTP_tableHeading"
                                style={{ fontWeight: "700" }}
                              >
                                Tour Date{" "}
                                <p className="MTP_data">
                                  {format(new Date(data.Date__c), "dd MMM yyyy")}
                                </p>
                              </div>
                              <div
                                className="col-lg-2 col-md-2 col-6 MTP_tableHeading"
                                style={{ fontWeight: "700" }}
                              >
                                MR Name
                                <br />
                                <span className="MTP_data">
                                  {data?.Contact__r?.Name}
                                </span>
                              </div>
                              <div
                                className="col-lg-2 col-md-2 col-6 MTP_tableHeading"
                                style={{ fontWeight: "700" }}
                              >
                                Tour Type{" "}
                                <p className="MTP_data">
                                  {data?.Type_of_Tour_Plan__c}
                                </p>
                              </div>
                              <div
                                className="col-lg-2 col-md-2 col-6 MTP_tableHeading"
                                style={{ fontWeight: "700" }}
                              >
                                {data?.Type_of_Tour_Plan__c === "Field Work" ? (
                                  <>
                                    No. of Customers
                                    <br />
                                    <span className="MTP_data">
                                      {data?.count}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    Place
                                    <br />
                                    <span className="MTP_data">
                                      {data?.Place__c}
                                    </span>
                                  </>
                                )}
                              </div>
                              <div
                                className="col-lg-2 col-md-2 col-6 MTP_tableHeading"
                                style={{ fontWeight: "700" }}
                              >
                                Status
                                <div className="MTP_data">
                                  {data?.Status__c === "Pending For Approval" ? (
                                    <img
                                      className="MTP_approved"
                                      src={question}
                                      alt=""
                                    />
                                  ) : data?.Status__c === "Delete Request Sent To Manager" ? (
                                    <img
                                      className="MTP_draft"
                                      src={question}
                                      alt=""
                                    />
                                  )
                                    : data?.Status__c === "Saved" ? (
                                      <img
                                        className="MTP_draft"
                                        src={draft}
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        className="MTP_approved"
                                        src={check}
                                        alt=""
                                      />
                                    )}
                                  <span
                                    style={{ color: "black", fontWeight: "500" }}
                                  >
                                    {data?.Status__c === "Delete Request Sent To Manager" && <span className="ml-1">Pending For Delete</span>}
                                  </span>
                                  {/* <p className="MTP_tableData">{data?.Date__c}</p> */}
                                </div>
                              </div>
                              <div
                                className="col-lg-2 col-md-2 col-6 MTP_tableHeading"
                                style={{ fontWeight: "700" }}
                              >
                                {data?.Type_of_Tour_Plan__c === "Field Work" ? (
                                  <Link
                                    to={{
                                      pathname: `/view-tours/${data?.TourPlan_SR_No__c}:${data?.Date__c}:${data?.Contact__c}`,
                                    }}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <p className="mt-3">
                                      <CommonButton
                                        title={"View Details"}
                                        width={"7rem"}
                                      />
                                    </p>
                                  </Link>
                                ) : (
                                  <Link
                                    to={{
                                      pathname: `/view-tours/${data?.TourPlan_SR_No__c}:${data?.Date__c}:${data?.Contact__c}:${data?.Place__c}`,
                                    }}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <p className="mt-3">
                                      <CommonButton
                                        title={"View Details"}
                                        width={"7rem"}
                                      />
                                    </p>
                                  </Link>
                                )}
                              </div>

                            </div>
                          </Card.Body>
                        </Card>
                      </div>

                    );
                  })}
                </div>
              </div>
              {hasMore && (
                <span onClick={loadMore}>
                  <p className="mt-5" style={{ fontWeight: 'bold' }}>Click 'Load More' to see more records.</p>
                  <CommonButton title={"Load More"} />
                </span>
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
      <ToastContainer />
      {loading && <Loader />}
    </div>
  );
};

export default ManagerApprovePlans;
