import React, { useEffect, useState } from "react";
import "./PlanMTP.css";
import BackButton from "../../Common/BackButton";
import { useParams } from "react-router-dom";
import Card from "react-bootstrap/Card";
import clockIcon from "./../../assets/images/circular-wall-clock-black-outline-17714.svg";
import locationIcon from "./../../assets/images/location-59.svg";
import profileIcon from "./../../assets/images/user-3297.svg";
import mobileIcon from "./../../assets/images/mobile-phone-2640.svg";
import { getTPForViewAllManagerApi } from "../../services/NTP/getTPForViewAllManagerApi";
import { useSelector } from "react-redux";
import Loader from "../../Common/Loader";
import doctorIcon from "../../assets/images/stethoscope-3539.svg";

const ViewPlannedMTP = () => {
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const UserId = useSelector((state) => state?.userDetails?.details?.Id);

  const { tourNo } = useParams();

  let [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [allDetails, setAllDetails] = useState([]);
  const [tourSrno, setTourSrNo] = useState("");
  const [tourDate, setTourDate] = useState("");
  const [place, setPlace] = useState("");
  const [customerPob, setCustomerPob] = useState("")

  useEffect(() => {
    if (tourNo !== undefined) {
      setTourSrNo(tourNo.split(":")?.[0]);
      setTourDate(tourNo.split(":")?.[1]);
      setPlace(tourNo.split(":")?.[2]);
    }
  }, [tourNo]);

  useEffect(() => {
    if (tourSrno && tourDate) {
      (async () => {
        setLoading(true);
        const response = await getTPForViewAllManagerApi(
          UserId,
          tourSrno,
          tourDate,
          place,
          accessToken
        );
        if (response?.status === 200) {
          const jsonData = response?.data;
          const data = JSON.parse(jsonData);
          // const customerCount = data?.customers.length * data.POB;
          setCustomerPob(data?.FixedPOB)
          console.log("data-->", data.customers.length)
          setAllDetails(data);
          setCustomers(data?.customers);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    }
  }, [tourSrno, tourDate, UserId, accessToken, place]);

  return (
    <div>
      <div className="container-fluid">
        <div className="row mt-4">
          <div
            className="col-lg-4 col-md-4 col-sm-12 col-12"
            style={{ background: "white" }}
          >
            <BackButton title={"View Tour Plan"} />
            <p className="mt-4 PlanMTP_leftHead">Select Day</p>
            <input
              className="CommonDropdown form-select ViewMTP_input"
              type="date"
              name="date"
              value={allDetails?.TPDate}
              disabled
            />
            <p className="mt-4 PlanMTP_leftHead">Station</p>
            <input
              className="CommonDropdown form-select ViewMTP_input"
              type="text"
              name="date"
              value={allDetails?.Station}
              disabled
            />
            <p className="mt-4 PlanMTP_leftHead">Type of Tour Plan</p>
            <input
              className="CommonDropdown form-select ViewMTP_input"
              type="text"
              name="text"
              value={allDetails?.TourPlan}
              disabled
            />
            {allDetails?.TourPlan === "Field Work" ? (
              <>
                <p className="mt-4 PlanMTP_leftHead ">HQ</p>
                <input
                  className="CommonDropdown form-select ViewMTP_input"
                  type="text"
                  name="text"
                  value={allDetails?.HQ}
                  disabled
                />
                <p className="mt-4 PlanMTP_leftHead ">VSE Name</p>
                <input
                  className="CommonDropdown form-select ViewMTP_input"
                  type="text"
                  name="text"
                  value={allDetails?.VSEName}
                  disabled
                />
                <p className="mt-4 PlanMTP_leftHead ">Route</p>
                <input
                  className="CommonDropdown form-select ViewMTP_input"
                  type="text"
                  name="text"
                  value={`${allDetails?.Route} - ${allDetails?.RouteDesc}`}
                  disabled
                />
                <p className="mt-4 PlanMTP_leftHead ">Enter POB</p>
                <input
                  className="CommonDropdown form-select ViewMTP_input"
                  type="text"
                  name="text"
                  value={customerPob}
                  disabled
                />
              </>
            ) : (
              <>
                {allDetails?.TourPlan === "Customer Engagement Activity" ? (
                  <>
                    <p className="mt-4 PlanMTP_leftHead ">
                      Type of Customer Engagement
                    </p>
                    <input
                      className="CommonDropdown form-select ViewMTP_input"
                      type="text"
                      name="text"
                      value={allDetails?.Engagement}
                      disabled
                    />
                  </>
                ) : (
                  ""
                )}
                <p className="mt-4 PlanMTP_leftHead">Start Time</p>
                <input
                  className="CommonDropdown form-select"
                  type="time"
                  value={allDetails?.StartTime}
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                  }}
                  disabled
                />
                <p className="mt-4 PlanMTP_leftHead">End Time</p>
                <input
                  className="CommonDropdown form-select"
                  type="time"
                  value={allDetails?.EndTime}
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                  }}
                  disabled
                />
                <p className="mt-4 PlanMTP_leftHead">Place</p>
                <input
                  className="CommonDropdown form-select"
                  type="text"
                  value={allDetails?.Place}
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                    marginBottom: "1rem",
                  }}
                  disabled
                />
              </>
            )}
          </div>
          {allDetails?.TourPlan === "Field Work" ? (
            <div className="col-lg-8 col-md-8 col-sm-12 col-12 mb-5">
              <div className="row">
                {customers?.map((doctor, index) => {
                  return (
                    <div
                      className="col-lg-6 col-md-6 col-sm-12 col-12"
                      key={index}
                    >
                      <Card className="mt-4 DoctorCard">
                        <Card.Body>
                          <div className="DoctorPharm_Name">
                            <span className="DoctorPharm_NameTitle">
                              {doctor?.Name}
                            </span>
                          </div>
                          <div
                            className="DoctorPharm_Name"
                            style={{ marginTop: "5px" }}
                          >
                            <span className="DoctorPharm_lastVisit">
                              <img
                                src={clockIcon}
                                alt="clock"
                                style={{
                                  height: "1rem",
                                  marginRight: "5px",
                                  marginBottom: "2px",
                                }}
                              />
                              <b>DOB: {doctor?.DOB}</b>
                            </span>
                            {/* <span className="DoctorPharm_badge2">{doctor.visitNo}</span> */}
                          </div>
                          <div className="DoctorPharm_Post">
                            <span className="mr-3">
                              <img
                                src={profileIcon}
                                alt="profile"
                                style={{
                                  height: "1rem",
                                  marginRight: "3px",
                                  marginBottom: "2px",
                                }}
                              />
                              {doctor?.ContactPerson}
                            </span>
                            <span>
                              <img
                                src={mobileIcon}
                                alt="mobile"
                                style={{
                                  height: "1rem",
                                  marginRight: "3px",
                                  marginBottom: "2px",
                                }}
                              />
                              {doctor?.MobileNumber}
                            </span>
                          </div>
                          <div className="DoctorPharm_Post">
                            <img
                              src={doctorIcon}
                              alt="location"
                              style={{
                                height: "1rem",
                                marginRight: "3px",
                                marginBottom: "2px",
                              }}
                            />
                            <span>Category: {doctor?.Category}</span>
                          </div>
                          <div className="DoctorPharm_Post">
                            <img
                              src={locationIcon}
                              alt="location"
                              style={{
                                height: "1rem",
                                marginRight: "3px",
                                marginBottom: "2px",
                              }}
                            />
                            {doctor?.Address}
                            <br />
                            <span>
                              {doctor?.Area},{doctor?.City}
                            </span>
                            <br />
                            <span>
                              {doctor?.State},{doctor?.Country}
                            </span>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default ViewPlannedMTP;