import { isLocalServer } from "../Components/isLocalServer";

export const getNodeServerUrl = () => {
    // check localhost
    if(isLocalServer())
        return 'http://localhost:5000';
    // check current environment from the .env file
    else if(process.env.REACT_APP_ENV === 'prod' || process.env.REACT_APP_ENV === 'production')
        return process.env.REACT_APP_PROD_URL;
    else if(process.env.REACT_APP_ENV === 'uat' || process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'dev')
        return process.env.REACT_APP_UAT_URL;
    else
        return 'https://hester-npharma-prod-0d10c55b2194.herokuapp.com';
}