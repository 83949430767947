import axios from "axios";
import { configs } from "../../config";
import { isLocalServer } from "../../Components/isLocalServer";
import { getNodeServerUrl } from "../../utils/getNodeServerUrl";

export const managerClaimActionApi = async (data, accessToken) => {
  // const nodeBaseUrl = isLocalServer()
  //   ? "http://localhost:5000"
  //   : "https://hester-npharma-uat-2ffe9a99645d.herokuapp.com";
  const nodeBaseUrl = getNodeServerUrl();
  try {
    const response = await axios.post(`${nodeBaseUrl}/expenses/claim-action`, {
      data,
      accessToken,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
